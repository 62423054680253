//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from "../../../components/page-header";

export default {
    components: {PageHeader},
    inject: ["hideLoading", "showLoading"],
    computed: {
        treeList() {
            return this.$store.state.department.treeList;
        }
    },
    data() {
        return {
            treeProps: {
                id: 'id',
                label: 'name',
                children: 'childrenList'
            },
            filterText: '',
            departmentInfo: {},
            edit: false,
            add: false,
            ids: [],
            departmentAddReqVO: {
                name: '',
                code: '',
                parentId: '',
                description: ''
            },
            departmentEditReqVO: {
                id: '',
                code: '',
                name: '',
                description: ''
            },
            changeTitle: '查看',
        };
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    created() {
        this.search();
    },
    methods: {
        /**
         * 查询部门树
         */
        async search() {
            this.edit = false;
            this.add = false;
            this.departmentInfo = {};
            this.showLoading();
            let res = await this.$store.dispatch(
                "department/getTree");
            this.hideLoading();
            let type = "error";
            if (res.code === 200) {
                type = "success";
            }
            this.$notify({
                title: "查询提示",
                message: res.msg,
                type: type,
                duration: 2000,
                position: "bottom-right",
            });
        },

        /**
         * tree的筛选功能
         */
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        /**
         * 点击的时候查询当前一级部门的详细数据
         */
        async nodeClick(val) {
            this.changeTitle = "查看";
            this.edit = false;
            this.add = false;
            this.showLoading();
            this.departmentInfo = await this.$store.dispatch(
                "department/getDepartmentById", val.id);
            this.hideLoading();
        },

        /**
         * 添加
         */
        addFu(val) {
            this.departmentAddReqVO.parentId = val.id;
            this.departmentInfo = {};
            this.add = true;
            this.edit = false;
            this.changeTitle = "新增";
        },

        /**
         * 编辑
         */
        editFu(val) {
            this.departmentEditReqVO.id = val.id;
            if (JSON.stringify(this.departmentInfo) === "{}") {
                this.nodeClick(val);
            }
            this.edit = true;
            this.add = false;
            this.changeTitle = "编辑";
        },

        /**
         * 确认提交
         */
        submit() {
            if (this.add) {
                this.departmentAddReqVO.code = this.departmentInfo.code;
                this.departmentAddReqVO.name = this.departmentInfo.name;
                this.departmentAddReqVO.description = this.departmentInfo.description;
                this.$confirm(`是否确认新增?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                }).then(async () => {
                    this.showLoading();
                    let changeRes = await this.$store.dispatch(
                        "department/addDepartment", this.departmentAddReqVO);
                    this.hideLoading();
                    let type = "error";
                    if (changeRes.code === 200) {
                        type = "success";
                        await this.search();
                    }
                    this.$notify({
                        title: "新增提示",
                        message: changeRes.msg,
                        type: type,
                        duration: 2000,
                        position: "bottom-right",
                    });
                }).catch(() => {
                });
            }
            if (this.edit) {
                this.departmentEditReqVO.code = this.departmentInfo.code;
                this.departmentEditReqVO.name = this.departmentInfo.name;
                this.departmentEditReqVO.description = this.departmentInfo.description;
                this.$confirm(`是否确认编辑?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                }).then(async () => {
                    this.showLoading();
                    let changeRes = await this.$store.dispatch(
                        "department/modifyDepartment", this.departmentEditReqVO);
                    this.hideLoading();
                    let type = "error";
                    if (changeRes.code === 200) {
                        type = "success";
                        await this.search();
                    }
                    this.$notify({
                        title: "编辑提示",
                        message: changeRes.msg,
                        type: type,
                        duration: 2000,
                        position: "bottom-right",
                    });
                }).catch(() => {
                });
            }
        },

        /**
         * 删除
         */
        deleteFu(val) {
            this.ids = [];
            this.getChildrenId([val]);
            this.$confirm(`是否删除该字典(${val.name})?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error",
            }).then(async () => {
                this.showLoading();
                let changeRes = await this.$store.dispatch(
                    "department/deleteByIds",
                    this.ids
                );
                this.hideLoading();
                let type = "error";
                if (changeRes.code === 200) {
                    type = "success";
                    await this.search();
                }
                this.$notify({
                    title: "删除提示",
                    message: changeRes.msg,
                    type: type,
                    duration: 2000,
                    position: "bottom-right",
                });
            }).catch(() => {
            });
        },

        /**
         * 递归获取id
         */
        getChildrenId(val) {
            val.forEach(item => {
                this.ids.push(item.id);
                if (item.childrenList && item.childrenList.length > 0) {
                    this.getChildrenId(item.childrenList);
                }
            })
        }
    },
};
